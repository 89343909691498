import { motion } from 'framer-motion';
import Logo1 from '../assets/levrij-logo-cloud-001.svg';
import Logo2 from '../assets/levrij-logo-cloud-002.svg';
import Logo3 from '../assets/levrij-logo-cloud-003.svg';
import Logo4 from '../assets/levrij-logo-cloud-004.svg';
import Logo5 from '../assets/levrij-logo-cloud-005.svg';
import Logo6 from '../assets/levrij-logo-cloud-006.svg';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Balancer from 'react-wrap-balancer';

export default function LogoCloud() {
  const {t} = useTranslation();
  return (
    <div id={'portfolio'} className="mx-auto max-w-7xl px-6 lg:px-8">
      <div
        className="mx-auto grid max-w-lg grid-cols-2 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:gap-x-10 sm:gap-y-4 lg:mx-0 lg:max-w-none lg:grid-cols-6">
        <a className={'col-span-3 sm:col-span-2'} target="_blank"
           href="https://pairprogram.com?ref=levrij.com">
          <motion.img
            whileHover={{y: -2.5}}
            className="max-h-12 w-full object-contain"
            src={Logo1}
            alt="PairProgram.com"
            width={158}
            height={48}
          />
        </a>
        <a className={'col-span-3 sm:col-span-2'} target="_blank" href="https://faang.io?ref=levrij.com">
          <motion.img
            whileHover={{y: -2.5}}
            className="max-h-12 w-full object-contain"
            src={Logo2}
            alt="FAANG.io"
            width={158}
            height={48}
          />
        </a>
        <a className={'col-span-3 sm:col-span-2'} target="_blank" href="https://domainpage.io?ref=levrij.com">
          <motion.img
            whileHover={{y: -2.5}}
            className="max-h-12 w-full object-contain"
            src={Logo3}
            alt="Domainpage.io"
            width={158}
            height={48}
          />
        </a>
        <a className={'col-span-3 sm:col-span-2'} target="_blank" href="https://sendpoint.io?ref=levrij.com">
          <motion.img
            whileHover={{y: -2.5}}
            className="max-h-12 w-full object-contain sm:col-start-2"
            src={Logo4}
            alt="Sendpoint.io"
            width={158}
            height={48}
          />
        </a>
        <a className={'col-span-3 sm:col-span-2'} target="_blank" href="https://theodoreai.com?ref=levrij.com">
          <motion.img
            whileHover={{y: -2.5}}
            className="max-h-12 w-full object-contain sm:col-start-2"
            src={Logo6}
            alt="TheodoreAI.com"
            width={158}
            height={48}
          />
        </a>
        <a className={'col-span-3 sm:col-span-2'} target="_blank" href="https://botroast.io?ref=levrij.com">
          <motion.img
            whileHover={{y: -2.5}}
            className="max-h-12 w-full object-contain sm:col-start-2"
            src={Logo5}
            alt="BotRoast.io"
            width={158}
            height={48}
          />
        </a>
      </div>
    </div>
  )
}
