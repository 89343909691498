import { HashLink } from 'react-router-hash-link';
import React from 'react';

const navigation = [
  {name: 'Portfolio', href: '/#portfolio'},
  {name: 'Features', href: '/#features'},
  {name: 'Pricing', href: '/#pricing'},
]

export default function Header() {
  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <HashLink smooth to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">levrij</span>
            <img
              className="h-10 w-auto"
              src="/logo.svg"
              alt=""
            />
          </HashLink>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <HashLink smooth key={item.name} to={item.href}
                      className="text-sm font-semibold leading-6 text-scale-900">
              {item.name}
            </HashLink>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <HashLink smooth to="/#book" className="text-sm font-semibold leading-6 text-scale-900">
            Book discovery call <span aria-hidden="true">&rarr;</span>
          </HashLink>
        </div>
      </nav>
    </header>
  )
}
