import { motion } from 'framer-motion';
import Boom from '../assets/shapes/53.svg';
import Balancer from 'react-wrap-balancer';
import SquigglyArrow from '../assets/shapes/30.svg';
import { commonNS } from '../i18n/config';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function CallToAction() {
  const {t} = useTranslation();
  return (
    <div className="relative -z-10 px-6 lg:px-8">
      <div
        className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-3xl sm:bottom-0 sm:right-[calc(50%-6rem)] sm:top-auto sm:translate-y-0 sm:transform-gpu sm:justify-end"
        aria-hidden="true"
      >
        <div
          className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-25"
          style={{
            clipPath:
              'polygon(73.6% 48.6%, 91.7% 88.5%, 100% 53.9%, 97.4% 18.1%, 92.5% 15.4%, 75.7% 36.3%, 55.3% 52.8%, 46.5% 50.9%, 45% 37.4%, 50.3% 13.1%, 21.3% 36.2%, 0.1% 0.1%, 5.4% 49.1%, 21.4% 36.4%, 58.9% 100%, 73.6% 48.6%)',
          }}
        />
      </div>
      <div id={'book'} className="mx-auto max-w-2xl text-center">
        <motion.div
          initial={{opacity: 0, y: -10}}
          whileInView={{opacity: 0.99, y: 0}}
          transition={{duration: 0.75, delay: .25}}
          className={'w-full flex items-center justify-center py-4 opacity-60'}>
          <img src={Boom} alt="Boom"/>
        </motion.div>
        <Balancer>
          <motion.h2
            initial={{x: -50, opacity: 0}}
            whileInView={{opacity: 1, x: 0, transition: {x: {duration: 0.5}}}}
            className="text-3xl font-bold tracking-tight text-scale-900 sm:text-4xl">
            {t('Home.CallToAction.Heading')}
          </motion.h2>
        </Balancer>
        <Balancer>
          <motion.p
            initial={{opacity: 0}}
            whileInView={{opacity: 1}}
            transition={{duration: 0.75, delay: 0.5}}
            className="mx-auto mt-6 max-w-xl text-lg leading-8 text-scale-600">
            {t('Home.CallToAction.Subheading')}
          </motion.p>
        </Balancer>
        <motion.div
          initial={{opacity: 0, y: -10}}
          whileInView={{opacity: 0.99, y: 0}}
          transition={{duration: 0.75, delay: .75}}
          className={'w-full flex justify-center py-8 opacity-60'}>
          <img src={SquigglyArrow} alt={"Squiggly Arrow"}/>
        </motion.div>
        <div className="flex items-center justify-center gap-x-6">
          <motion.div
            initial={{y: 50, opacity: 0}}
            animate={{y: 0, opacity: 1, transition: {delay: 1, y: {duration: 0.5}, opacity: {duration: 1}}}}
            whileHover={{y: -2.5}}
            className="flex items-center justify-center gap-x-6">
            <div className="relative inline-flex group">
              <div
                className="absolute transition-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt">
              </div>
              <motion.button
                initial={{y: 50, opacity: 0}}
                animate={{y: 0, opacity: 1}}
                whileHover={{y: -2.5}}
                whileTap={{scale: 0.975}}
                data-cal-link="levrij/discovery"
                data-cal-config='{"layout":"month_view"}'
                className="relative z-1 rounded-md bg-brand-600 px-7 py-5 text-lg font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
              >
                {t('Button.Book', {ns: commonNS})}
              </motion.button>
            </div>
          </motion.div>
        </div>
      </div>
      <div
        className="absolute left-1/2 right-0 top-full -z-10 hidden -translate-y-1/2 transform-gpu overflow-hidden blur-3xl sm:block"
        aria-hidden="true"
      >
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
  )
}
