import Balancer from 'react-wrap-balancer';
import { motion } from 'framer-motion';
import React from 'react';
import i18n from '../i18n/config';
import { BeakerIcon, CursorArrowRaysIcon, HandThumbUpIcon, RectangleGroupIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

const features = [
  {
    name: i18n.t('Home.Features.Feature1.Heading'),
    description: i18n.t('Home.Features.Feature1.Subheading'),
    icon: BeakerIcon,
  },
  {
    name: i18n.t('Home.Features.Feature2.Heading'),
    description: i18n.t('Home.Features.Feature2.Subheading'),
    icon: RectangleGroupIcon,
  },
  {
    name: i18n.t('Home.Features.Feature3.Heading'),
    description: i18n.t('Home.Features.Feature3.Subheading'),
    icon: CursorArrowRaysIcon,
  },
  {
    name: i18n.t('Home.Features.Feature4.Heading'),
    description: i18n.t('Home.Features.Feature4.Subheading'),
    icon: HandThumbUpIcon,
  },
];

export default function Features() {
  const { t } = useTranslation();
  return (
    <div id={'features'} className="mx-auto mt-8 max-w-7xl px-6 sm:mt-16 lg:px-8">
      <div className="mx-auto max-w-2xl lg:text-center">
        <h2 className="text-base font-semibold leading-7 text-brand-600">{t('Home.Features.Preheading')}</h2>
        <Balancer>
          <p className="mt-2 text-3xl font-semibold tracking-tight text-scale-900 sm:text-4xl">
            {t('Home.Features.Heading')}
          </p>
        </Balancer>
        <Balancer>
          <p className="mt-6 text-lg leading-8 text-scale-600">
            {t('Home.Features.Subheading')}
          </p>
        </Balancer>
      </div>
      <motion.div
        initial="hidden"
        animate="show"
        variants={{
          hidden: {opacity: 0},
          show: {
            opacity: 1,
            transition: {
              staggerChildren: 0.33
            }
          }
        }}
        className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
        <dl
          className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          {features.map((feature) => (
            <motion.div
              variants={{
                hidden: {opacity: 0},
                show: {opacity: 1, transition: {duration: 0.33}}
              }}
              key={feature.name} className="relative pl-16">
              <dt className="text-base font-semibold leading-7 text-scale-900">
                <div
                  className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-brand-600">
                  <feature.icon className="h-6 w-6 text-white" aria-hidden="true"/>
                </div>
                {feature.name}
              </dt>
              <dd className="mt-2 text-base leading-7 text-scale-600">{feature.description}</dd>
            </motion.div>
          ))}
        </dl>
      </motion.div>
    </div>
  )
}
