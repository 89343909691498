import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import { Provider as ReactWrapBalancerProvider } from 'react-wrap-balancer'
import '@fontsource-variable/manrope';
import '@fontsource-variable/inter';
import '@fontsource/rubik-mono-one';

export default function App() {
  return (
    <ReactWrapBalancerProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
        </Routes>
      </BrowserRouter>
    </ReactWrapBalancerProvider>
  )
}
