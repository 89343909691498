import { CheckIcon } from '@heroicons/react/20/solid'
import { LockClosedIcon } from '@heroicons/react/24/outline';
import Star from '../assets/shapes/29.svg';
import SquigglyLine from '../assets/shapes/5.svg';
import { motion } from 'framer-motion';
import { Trans, useTranslation } from 'react-i18next';
import i18n, { commonNS } from '../i18n/config';
import Balancer from 'react-wrap-balancer';

const includedFeatures = [
  i18n.t('Home.Pricing.Option1.Feature1'),
  i18n.t('Home.Pricing.Option1.Feature2'),
  i18n.t('Home.Pricing.Option1.Feature3'),
  i18n.t('Home.Pricing.Option1.Feature4'),
]

export default function Pricing() {

  const {t} = useTranslation();

  return (
    <div id={'pricing'} className="pt-24 sm:pt-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <motion.div
            initial={{opacity: 0, y: -10}}
            whileInView={{opacity: 0.99, y: 0}}
            transition={{duration: 0.75, delay: .25}}
            className={'w-full flex items-center justify-center py-4 opacity-60'}>
            <img src={Star} alt="Star"/>
          </motion.div>

          <Balancer>
            <h2
              className="text-3xl font-bold tracking-tight text-scale-900 sm:text-4xl">{t('Home.Pricing.Heading')}</h2>
          </Balancer>
          <Balancer>
            <p className="mt-6 text-lg leading-8 text-scale-600">
              {t('Home.Pricing.Subheading')}
            </p>
          </Balancer>
        </div>
        <div
          className="z-10 relative bg-white mx-auto mt-16 max-w-2xl rounded-3xl border-dashed border-scale-200 border-4 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3
              className="text-4xl font-black drop-shadow-md tracking-wide text-scale-800">{t('Home.Pricing.Option1.Heading')}</h3>
            <p className="mt-6 text-base leading-7 text-scale-600">
              {
                <Trans i18nKey={'Home.Pricing.Option1.Subheading'}>
                  Launch your <span title={t('Home.Pricing.Option1.Tooltip')} className={'cursor-help font-semibold underline decoration-dashed decoration-scale-300 decoration-4'}>Minimum Viable Product (MVP)</span> in 4 weeks. Perfect for idea validation and early-stage startups who don't have a technical co-founder.
                </Trans>
              }
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4
                className="flex-none text-sm font-semibold leading-6 text-brand-600">{t('Home.Pricing.Option1.WhatsIncluded')}</h4>
              <div className="h-px flex-auto bg-scale-100"/>
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-scale-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-brand-600" aria-hidden="true"/>
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div
              className="rounded-2xl bg-scale-50 py-10 text-center ring-1 ring-inset ring-scale-900/5 lg:flex lg:flex-col lg:justify-center lg:py-24">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-scale-600">{t('Home.Pricing.Option1.WhatsIncluded')}</p>
                <p
                  className="mt-6 text-sm font-semibold leading-6 tracking-wide text-scale-600">{t('Home.Pricing.StartingAt')}</p>
                <p className="flex items-baseline justify-center gap-x-2">
                  <span className="text-6xl font-extrabold tracking-tight text-scale-900"><span
                    className={'highlight highlight-yellow-400 highlight-variant-1'}>{t('Home.Pricing.Option1.Price')}</span></span>
                  <span
                    className="text-sm font-semibold leading-6 tracking-wide text-scale-600">{t('Home.Pricing.Option1.Currency')}</span>
                </p>
                <button
                  className="mt-10 block w-full rounded-md bg-brand-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                  data-cal-link="levrij/discovery"
                  data-cal-config='{"layout":"month_view"}'
                >
                  {t('Button.Book', {ns: commonNS})}
                </button>
                {/*<div className="mt-6 inline-flex items-center">*/}
                {/*  <LockClosedIcon className="inline-block h-4 w-4 mr-2 text-scale-500" aria-hidden="true"/>*/}
                {/*  <p className="text-xs leading-5 text-scale-600">*/}
                {/*    {t('Home.Pricing.SecureCheckout')}*/}
                {/*  </p>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
        <motion.img
          initial={{opacity: 0, x: 50}}
          whileInView={{opacity: 0.99, x: 0}}
          transition={{duration: 0.75, delay: .25}}
          style={{zIndex: 0}} className={'hidden sm:block absolute -mt-48 right-0 rotate-12 opacity-60'}
          src={SquigglyLine} alt="Squiggly Line"/>
      </div>
    </div>
  )
}
