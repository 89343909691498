import { motion } from 'framer-motion';
import ActionLine from '../assets/shapes/81.svg';
import Pricing from '../components/Pricing';
import Footer from '../components/Footer';
import CallToAction from '../components/CallToAction';
import Header from '../components/Header';
import Features from '../components/Features';
import Hero from '../components/Hero';
import React, { useEffect } from 'react';
import { getCalApi } from '@calcom/embed-react';
import { useTranslation } from 'react-i18next';

const faqs = [
  {
    id: 1,
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  // More questions...
]

export default function Home() {
  const {t} = useTranslation();

  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal("ui", {
        "styles": {"branding": {"brandColor": "#000000"}},
        "hideEventTypeDetails": false,
        "layout": "month_view"
      });
    })();
  }, []);

  return (
    <div className="bg-white">
      <Header/>

      <main className="isolate">
        {/* Hero section */}
        <Hero/>

        <motion.div
          initial={{opacity: 0, y: -10}}
          whileInView={{opacity: 0.99, y: 0}}
          transition={{duration: 0.75, delay: .25}}
          className={'w-full flex justify-center opacity-60'}>
          <img src={ActionLine} alt={"Action Line"}/>
        </motion.div>

        <Features/>

        <Pricing/>

        {/* FAQs */}
        <div
          className="mt-10 mx-auto max-w-2xl divide-y divide-scale-900/10 px-6 pb-8 sm:pb-24 sm:pt-12 lg:max-w-7xl lg:px-8 lg:pb-32">
          {/*<h2 className="text-2xl font-bold leading-10 tracking-tight text-scale-900">Frequently asked questions</h2>*/}
          {/*<dl className="mt-10 space-y-8 divide-y divide-scale-900/10">*/}
          {/*  {faqs.map((faq) => (*/}
          {/*    <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">*/}
          {/*      <dt className="text-base font-semibold leading-7 text-scale-900 lg:col-span-5">{faq.question}</dt>*/}
          {/*      <dd className="mt-4 lg:col-span-7 lg:mt-0">*/}
          {/*        <p className="text-base leading-7 text-scale-600">{faq.answer}</p>*/}
          {/*      </dd>*/}
          {/*    </div>*/}
          {/*  ))}*/}
          {/*</dl>*/}
        </div>

        {/* CTA section */}
        <CallToAction/>
      </main>

      <Footer/>
    </div>
  )
}
