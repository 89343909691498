import { classNames } from '../utils';
import Marquee from './Marquee';
import Balancer from 'react-wrap-balancer';
import React from 'react';
import { t } from 'i18next';
import { TrophyIcon } from '@heroicons/react/24/outline';
import { AwardIcon } from 'lucide-react';
import { Trans } from 'react-i18next';


const logos = [
  {
    name: "Botroast&#0046;io - Design&#0032;feedback&#0032;in&#0032;1&#0032;click | Product Hunt",
    img: "https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=439556&theme=light&period=daily",
  },
  {
    name: "Botroast&#0046;io - Design&#0032;feedback&#0032;in&#0032;1&#0032;click | Product Hunt",
    img: "https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=439556&theme=light&period=weekly&topic_id=93",
  },
  {
    name: "Botroast&#0046;io - Design&#0032;feedback&#0032;in&#0032;1&#0032;click | Product Hunt",
    img: "https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=439556&theme=light&period=weekly&topic_id=44",
  },
  {
    name: "Botroast&#0046;io - Design&#0032;feedback&#0032;in&#0032;1&#0032;click | Product Hunt",
    img: "https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=439556&theme=light&period=monthly&topic_id=93",
  },
];

const Logo = ({ name, img }: { name: string; img: string }) => {
  return (
    <div className={classNames("cursor-pointer")}>
      <img
        alt={name}
        src={img}
        style={{width: 250, height: 54}}
        width="250"
        height="54"
      />
    </div>
  );
};

export default function AccoladeMarquee() {
  return (
    <div
      className="relative flex h-full w-full flex-col items-center justify-center gap-4 overflow-hidden rounded-lg py-12">
      {/*<div className="flex items-center justify-center w-full">*/}
      {/*  <AwardIcon className="h-12 w-12 text-yellow-500" />*/}
      {/*</div>*/}
      <h2 className="pb-4 text-center text-lg font-semibold leading-8 tracking-wide text-scale-600">
        <Balancer>
          <Trans i18nKey="Home.Accolades.Heading">
            We build <span
            className={'font-bold highlight highlight-yellow-400 highlight-variant-15'}>award-winning</span> products
          </Trans>
        </Balancer>
      </h2>
      <Marquee className="[--gap:3rem]">
        {logos.map((logo, idx) => (
          <Logo key={idx} {...logo} />
        ))}
      </Marquee>
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white"></div>
    </div>
  );
};

