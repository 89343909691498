import { HashLink } from 'react-router-hash-link';
import React from 'react';

const footerNavigation = {
  solutions: [
    {name: 'Software Prototyping', href: '/#features'},
    {name: 'Software Development', href: '/#features'},
    {name: 'MVP Development', href: '/#features'},
    {name: 'Web App Development', href: '/#features'},
  ],
  support: [
    {name: 'Pricing', href: '/#pricing'},
    {name: 'Book call', href: '/#book'},
  ],
  company: [
    {name: 'Our Work', href: '/#portfolio'},
    // {name: 'Blog', href: '#'},
    // {name: 'Jobs', href: '#'},
    // {name: 'Press', href: '#'},
    // {name: 'Partners', href: '#'},
  ],
  legal: [
    // {name: 'Claim', href: '#'},
    {name: 'Privacy', href: '#'},
    {name: 'Terms', href: '#'},
  ],
}

export default function Footer() {
  return (
    <div className="mx-auto mt-32 max-w-7xl px-6 lg:px-8">
      <footer
        aria-labelledby="footer-heading"
        className="relative border-t border-scale-900/10 py-24 sm:mt-56 sm:py-32"
      >
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <img
            className="h-7"
            src="/logo.svg"
            alt="levrij"
          />
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-scale-900">Solutions</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.solutions.map((item) => (
                    <li key={item.name}>
                      <HashLink smooth to={item.href}
                                className="text-sm leading-6 text-scale-600 hover:text-scale-900">
                        {item.name}
                      </HashLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-scale-900">Support</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.support.map((item) => (
                    <li key={item.name}>
                      <HashLink smooth to={item.href}
                                className="text-sm leading-6 text-scale-600 hover:text-scale-900">
                        {item.name}
                      </HashLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-scale-900">Company</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.company.map((item) => (
                    <li key={item.name}>
                      <HashLink smooth to={item.href}
                                className="text-sm leading-6 text-scale-600 hover:text-scale-900">
                        {item.name}
                      </HashLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-scale-900">Legal</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.legal.map((item) => (
                    <li key={item.name}>
                      <HashLink smooth to={item.href}
                                className="text-sm leading-6 text-scale-600 hover:text-scale-900">
                        {item.name}
                      </HashLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
