import Balancer from 'react-wrap-balancer';
import { motion } from 'framer-motion';
import { Trans, useTranslation } from 'react-i18next';
import Arrow2 from '../assets/shapes/57.svg';
import { commonNS } from '../i18n/config';
import SquigglyLine from '../assets/shapes/93.svg';
import MvpGraphic from '../assets/graphics/mvp-003.svg';
import SemiActionLine from '../assets/shapes/15.svg';
import Pairprogram from '../assets/pairprogram2.png';
import Faang from '../assets/faang.png';
import Sendpoint from '../assets/sendpoint.png';
import React from 'react';
import LogoCloud from './LogoCloud';
import { TrophyIcon } from '@heroicons/react/24/outline';
import Accolades from './Accolades';
import AccoladeMarquee from './AccoladeMarquee';

export default function Hero() {
  const {t} = useTranslation();
  return (
    <div className="relative pt-14">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="py-12 sm:py-18">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-3xl text-center">
            <Balancer>
              <motion.h1
                initial={{y: -50, opacity: 0}}
                whileInView={{opacity: 1, y: 0,}}
                transition={{duration: 0.75, delay: 0.25}}
                className="text-4xl font-bold tracking-tight text-scale-900 sm:text-6xl flex-wrap leading-10"
              >
                <Trans i18nKey="Home.Hero.Heading">
                  Launch your dream startup in <span
                  className={'highlight highlight-yellow-400 highlight-variant-15'}>28</span> days
                </Trans>
              </motion.h1>
            </Balancer>
            <Balancer>
              <motion.p
                initial={{opacity: 0}}
                whileInView={{opacity: 1}}
                transition={{duration: 0.75, delay: 0.5}}
                className="mt-6 text-lg leading-8 text-scale-600">
                {t('Home.Hero.Subheading')}
              </motion.p>
            </Balancer>
            <motion.div
              initial={{opacity: 0}}
              whileInView={{opacity: 0.99}}
              transition={{duration: 0.75, delay: .75}}
              className={'w-full flex items-center justify-center py-8 opacity-60'}>
              <img className={'max-h-[80px]'} src={Arrow2} alt="Arrow"/>
            </motion.div>

            <motion.div
              initial={{y: 50, opacity: 0}}
              animate={{y: 0, opacity: 1, transition: {delay: 1, opacity: {duration: 1}}}}
              className="flex items-center justify-center gap-x-6">
              <div className="relative inline-flex group">
                <div
                  className="absolute transition-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt">
                </div>
                <motion.button
                  whileTap={{scale: 0.975}}
                  whileHover={{y: -2.5}}
                  data-cal-link="levrij/discovery"
                  data-cal-config='{"layout":"month_view"}'
                  className="relative z-1 rounded-md bg-brand-600 px-7 py-5 text-lg font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                >
                  {t('Button.Book', {ns: commonNS})}
                </motion.button>
              </div>
            </motion.div>
          </div>

          <AccoladeMarquee/>

          {/* Logo cloud */}
          <LogoCloud/>


          <motion.div
            initial={{opacity: 0, y: -10}}
            whileInView={{opacity: 0.99, y: 0}}
            transition={{duration: 0.75, delay: .75}}
            className={'pt-24 w-full flex justify-center opacity-60'}>
            <img src={SquigglyLine} alt={"Squiggle Line"}/>
          </motion.div>

          {/* What is an MVP? */}
          <div className="sm:py-8 overflow-hidden">
            <div className="mt-16 mx-auto max-w-7xl px-6 lg:flex lg:px-8">
              <div
                className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
                <div className="lg:col-end-1 lg:w-full lg:max-w-2xl lg:pb-8">
                  <Balancer>
                    <motion.h2
                      initial={{scale: 0.95, opacity: 0, x: -100}}
                      whileInView={{opacity: 1, scale: 1, x: 0, transition: {delay: 0.25, duration: 0.5}}}
                      className="text-3xl font-bold tracking-tight text-scale-900 sm:text-4xl">
                      {t('Home.What.Heading')}
                    </motion.h2>
                  </Balancer>
                  <Balancer>
                    <motion.p
                      initial={{opacity: 0}}
                      whileInView={{opacity: 1}}
                      transition={{duration: 0.75, delay: 0.5}}
                      className="mt-6 text-xl leading-8 text-scale-600">
                      {t('Home.What.Subheading')}
                    </motion.p>
                  </Balancer>
                  <div className="mt-6">
                    <motion.button
                      initial={{y: 50, opacity: 0}}
                      animate={{y: 0, opacity: 1}}
                      whileHover={{y: -2.5}}
                      whileTap={{scale: 0.975}}
                      transition={{duration: 0.75, delay: 0.5}}
                      data-cal-link="levrij/discovery"
                      data-cal-config='{"layout":"month_view"}'
                      className="rounded-md bg-brand-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                    >
                      {t('Button.Book', {ns: commonNS})}
                    </motion.button>
                  </div>
                </div>
                <div className={'mt-24 rotate-12'}>
                  <motion.img
                    initial={{x: 50, opacity: 0}}
                    whileInView={{opacity: 1, x: 0, transition: {x: {duration: 0.5}}}}
                    className={'max-w-xl'}
                    src={MvpGraphic}
                    alt="PairProgram.com"
                  />
                </div>
              </div>
            </div>
          </div>


          <motion.div
            initial={{opacity: 0, y: -10}}
            whileInView={{opacity: 0.99, y: 0}}
            transition={{duration: 0.75, delay: .75}}
            className={'pt-24 w-full flex justify-center opacity-60'}>
            <img src={SemiActionLine} alt={"Semi-Action Line"}/>
          </motion.div>

          {/* Benefits of an MVP Section */}
          <div className="sm:py-8 overflow-hidden">
            <div className="mt-16 mx-auto max-w-7xl px-6 lg:flex lg:px-8">
              <div
                className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
                <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                  <motion.h2
                    initial={{scale: 0.95, opacity: 0, x: -100}}
                    whileInView={{opacity: 1, scale: 1, x: 0, transition: {delay: 0.25, duration: 0.5}}}
                    className="text-3xl font-bold tracking-tight text-scale-900 sm:text-4xl">
                    {t('Home.Benefits.Heading')}
                  </motion.h2>
                  <motion.p
                    initial={{opacity: 0}}
                    whileInView={{opacity: 1}}
                    transition={{duration: 0.75, delay: 0.5}}
                    className="mt-6 text-xl leading-8 text-scale-600">
                    {t('Home.Benefits.Subheading')}
                  </motion.p>
                  <div className="mt-6">

                    <motion.button
                      initial={{y: 50, opacity: 0}}
                      animate={{y: 0, opacity: 1}}
                      whileHover={{y: -2.5}}
                      whileTap={{scale: 0.975}}
                      transition={{duration: 0.75, delay: 0.5}}
                      data-cal-link="levrij/discovery"
                      data-cal-config='{"layout":"month_view"}'
                      className="rounded-md bg-brand-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                    >
                      {t('Button.Book', {ns: commonNS})}
                    </motion.button>
                  </div>
                </div>
                <motion.div
                  animate={{
                    transition: {
                      delayChildren: 0.5,
                      staggerChildren: 0.5
                    }
                  }}
                  className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
                  <motion.div
                    initial={{opacity: 0, y: -50}}
                    whileInView={{opacity: 1, y: 0, transition: {y: {duration: 0.5}}}}
                    whileHover={{y: -5}}
                    className="shadow-2xl border border-scale-200 rounded-2xl w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                    <img
                      src={Pairprogram}
                      alt="PairProgram.com"
                      className="aspect-[16/9] w-[37rem] max-w-none rounded-2xl bg-scale-50 object-cover"
                    />
                  </motion.div>
                  <div
                    className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
                    <motion.div
                      initial={{opacity: 0, y: 25}}
                      whileInView={{opacity: 1, y: 0, transition: {y: {duration: 0.5}}}}
                      whileHover={{y: -5}}
                      className="shadow-xl border border-scale-200 rounded-2xl flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                      <img
                        src={Faang}
                        alt="FAANG.io"
                        className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-scale-50 object-cover"
                      />
                    </motion.div>
                    <motion.div
                      initial={{opacity: 0, x: 50}}
                      whileInView={{opacity: 1, x: 0, transition: {x: {duration: 0.5}}}}
                      whileHover={{y: -5}}
                      className="shadow-md border border-scale-200 rounded-2xl hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                      <img
                        src={Sendpoint}
                        alt="Sendpoint.io"
                        className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-scale-50 object-cover"
                      />
                    </motion.div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
  )
}
