import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import translationEN from "./en/translation.json";
import commonEN from "./en/common.json";
export const defaultNS = "translation";
export const translationNS = "translation";
export const commonNS = "common";
export const resources = {
  en: {
    translation: translationEN,
    common: commonEN,
  },
} as const;

i18n
.use(initReactI18next)
.init({
  debug: true,
  lng: "en",
  ns: [translationNS, commonNS],
  defaultNS,
  resources,
}).catch(err => console.error(err));

export default i18n;
